<!--
服务订单
-->
<template>
  <div class="mainBox">
    <!---->
    <div class="shipMain shipMainNoPad2">
      <iframe class="iframeBox" :src="url" frameborder="0"></iframe>
    </div>
  </div>
</template>
<script>
import { GetNurseBedReportUrl } from "../../api/homeGuardianship";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      url: "",
    };
  },
  created() {},

  mounted: function () {
    if (this.mattressInfo.id) {
      this.getNurseBedReportUrl();
    }
  },
  destroyed: function () {},
  computed: {
    ...mapState(["mattressInfo"]),
  },
  watch: {
    mattressInfo: {
      handler(newVal) {
        if (newVal.id) {
          this.getNurseBedReportUrl();
        }
      },
    },
  },
  methods: {
    getNurseBedReportUrl() {
      GetNurseBedReportUrl(this.mattressInfo.id).then((res) => {
        console.log(res);
        this.url = res.result;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iframeBox {
  width: 100%;
  min-height: 666px;
}
.shipMainNoPad2 {
  padding: 0 !important;
}
</style>
